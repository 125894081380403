import React from 'react'

import andy_small from './img/andy_sq_small.jpg'

const Avatar = ({ src, alt = 'Avatar', size = 'w-16 h-16' }) => {
	return (
		<div className={`${size} rounded-full overflow-hidden inline-block border border-gray-200`}>
			<img src={src} alt={alt} className="w-full h-full object-cover" />
		</div>
	)
}

const pageVisit = (url) => {
	if(window.rdt){
		window.rdt('track', 'Custom', {
			"customEventName": "Portfolio View",
			"portfolio_id": url
		})
	
	} else {
		console.log("rdt not set up :(")
	}
}

const entries = [{
	name: 'Andy - the original mullet guy',
	date: 'March 2024',
	url: 'https://www.hire-andy.lol',
	message: "This whole thing started here, getting laid off sucks. It sucked less when I got more attention from companies / recruiters."
// }, {
// 	name: 'Christian B.',
// 	date: 'April 2024',
// 	url: 'www.google.com',
// 	message: "Temporary thing goes here"
}]

function App() {
	return (
		<div className="min-h-screen flex flex-col items-center px-4 py-10 max-w-3xl w-full mx-auto">
			<header className="text-left max-w-4xl w-full mb-10 p-1">
				<h1 className="text-4xl font-semibold text-gray-900 mb-4">Turn Your Resume Into A Portfolio</h1>
				<p>Professional portfolio design and hosting by Andy, an internet stranger.</p>
				<hr className="mt-8 border-gray-300 w-full max-w-3xl mx-auto" />
			</header>
			<article>
				<div className="items-left text-lg text-gray-700 shadow-md p-8 rounded-xl mb-6">
					<section>
						<p>
							My name is Andy, and I was recently laid off. <span className='italic'>Awesome.</span>
							<br />
							<br />
							In the days following the most scripted meeting in the world, I sent out 41 applications. I was feeling pretty confident in my outreach - there was a TON of turmoil at my previous company, so I had kept my resume up to date and periodically sent it through ATS checkers.
							<br />
							<br />
							What I refused to believe at the time, was nobody wants to hire the mullet-guy.
						</p>
					</section>

					<section>
						<div className='text-3xl font-semibold my-6 italic'>Mullet Guy meets Icarus</div>
						<p>
							For years, I've been of the mind that any company that hires me for <span className='italic'>me</span> will be participating in a much more comfortable and sustainable relationship. Or at least it would if I didn't unironically like the 80s Mullet/Mustache combo, and scare away all my potential employers.
							<br />
							<br />
							I flew too close to the sun. It turns out leaning too far into a theme verges on "weird and unprofessional." I imagine the people who took their time responding were probably passing around my resume picture to poke fun (it was totally warranted though - I may as well have sent along headshots for Senior Engineering positions).
							<br />
							<br />
							The "No" emails started to arrive in droves over the next week, and I pretty quickly fell back down into that familiar pit of despair.
						</p>
					</section>
					<section>
						<div className='text-3xl font-semibold my-6 italic'>The Ballad of Colin McIntosh <span className='text-[16px] font-medium text-gray-600'>and Nate</span></div>
						<p>
							As it happens, I'm not the only one to go through "organizational changes," and neither are you if that's why you're here.
							<br />
							<br />
							A couple of dudes - who seem pretty cool by the way - used to be fancy recruiters for some of the bigger tech companies; Apple, Amazon, Facebook, etc.
							<br />
							<br />
							Well one of them posted on reddit offering some general advice on how to make your resume more attractive to employers without making up positions like <span className='italic font-serif'>Senior Executive Engineering Principal at Fry's Electronics</span> (R.I.P. Fry's).
							<br />
							<br />
							In the 6 years it's been since that original post, they've helped thousands and thousands of people - in the 2 minutes I took to grab a copy of their template, I saw 16-17 other active viewers.
							<br />
							<br />
							Also in that 6 years, Colin started a bedding company and seems to be super busy with it. Despite the hectic schedule, he routinely makes himself available for 1-on-1 consults and has helped TONS of people land jobs across the world.
							<br />
							<br />
							Nate started a software design studio way back, Sidebench. He's got a crazy work ethic and is still clocking in for another 9-5 while offering his insight and interpretive abilities to job seekers. Nate is actually the guy that first hired Colin, two separate times.
							<br />
							<br />
							Thousands of people every month go and visit their brainchild, <a className="text-blue-600 font-semibold hover:text-blue-800 underline" href="https://sheetsresume.com" rel="noreferrer" target="_blank" alt="Sheet Resume">SheetsResume.com</a>.
						</p>
					</section>

					<section>
						<div className='text-3xl font-semibold my-6 italic'>Mullet Guy's Redemption</div>
						<p>
							I found Colin's original post on Reddit before finding his website and 1-on-1 services. As quickly as I could, I slapped together a new resume using his template, and sent it off to each recipient of the next round of applications, and within DAYS I was still unemployed, but much happier with the quailty of my resume.
							<br />
							<br />
							My resume is now being viewed 2, 3 times by many of these places - I'm still in the running for multiple Senior positions, wheras before I'd get that "thanks, but no thanks" email. I attribute the turn around in positive attention to Colin and Nate's blog posts and to a <a className="text-blue-600 font-semibold hover:text-blue-800 underline" href="https://hire-andy.lol" rel="noreferrer" target="_blank" alt="Hire Andy, PLEASE">redesign of my portfolio</a>, to match my newfound professionalism.
						</p>
					</section>

					<section>
						<div className='text-3xl font-semibold my-6 italic'>Redemption, at a Cost</div>
						<p>
							It's not all sunshine and rainbows, there was a painful but fully expected penalty due immediately - the mullet has joined it's fallen comrades; socks with man sandals, and way-above-the-knee denim dad shorts.
							<br />
							<br />
							I'm still of the mind that a company that hires me for <span className='italic'>me</span> will ultimately end up with both of us happier for longer, but I've realized I need to keep some of the more egregious quirks in check, until they make an offer at least.
						</p>
					</section>

					<div className='text-3xl font-semibold my-6 italic'>Alright, on to the pitch. Ugh.</div>

				</div>
			</article>


			<div className="bg-slate-100 shadow-xl rounded-lg p-8 mb-6 w-full flex flex-col sm:flex-row">
				<div className='w-full sm:w-2/3'>
					<h2 className="text-2xl text-gray-800 mb-4">Turn your resume into a professional website portfolio</h2>
					<ul className="text-gray-600 mb-6 flex flex-col">
						<li className='mt-2'>✓ Personal Domain Name</li>
						<li className='mt-2'>✓ Modern, Responsive Design</li>
						<li className='mt-2'>✓ SEO Optimized & SSL Secure</li>
						<li className='mt-2'>✓ Direct Email Communication</li>
						<li className='mt-2'>✓ Free Experience Updates, Forever</li>
						<li className='mt-2'>✓ Alerts for Resume Downloads</li>
						<li className='mt-2'>✓ Analytics on Portfolio Usage</li>
					</ul>
				</div>

				<div className='w-full sm:w-1/3 md:border-l'>
					<div className="sm:pl-8 flex flex-col h-full justify-between">
						<div className="text-gray-800 text-lg mb-4 pt-4 sm:p-0 flex grow flex-col sm:justify-between">
							<div className='text-center grow-0'>Total Price:</div>
							<div className="font-semibold text-4xl text-center text-gray-700 flex justify-center items-center grow">$50*</div>
						</div>
						<p className="text-sm text-gray-400 my-2">$50 deposit to secure domain and begin development. Turn around time is 1-3 days (usually)</p>
						{/* <p className="text-sm text-gray-400 my-2">*Starting in May price will increase to $250 with a $50 deposit</p> */}
					</div>
				</div>
			</div>

			<div className="bg-white shadow-md rounded-lg p-8">
				<h2 className="text-2xl text-gray-800 mb-4">How it works</h2>
				<p className="text-gray-600 mb-4">To kick off your new portfolio, start with:</p>
				<ol className="text-gray-600 mb-6 list-decimal list-inside">
					<li>An email to <a href="mailto:info@resume-to-portfolio.com" className="text-blue-500">info@resume-to-portfolio.com</a> with your resume attached.</li>
					<li>In your email, suggest a few desired domain names for your portfolio, like <span className="italic">john-doe.com</span> or <span className="italic">johnny-want-jobs.com</span>. We'll be able to secure the best available option.</li>
					<li>Include some style suggestions - light theme, dark, blue with orange accents, etc. Be aware that visual simplicity is key - a subtle air of professionalism is much better than an over-engineered or visually noisy portfolio.</li>
					<li>Once your deposit is received, I'll spin up a website and start building.</li>
					<li>Feel free to email questions, clarification, ask for updates, and submit feedback.</li>
				</ol>
				<p className="text-gray-600">Next steps on this end:</p>
				<ul className="text-gray-600 mb-6 list-disc list-inside">
					<li>Design a custom, modern, and responsive website based on your resume.</li>
					<li>Optimize the site for search engines. Too much or too little is bad.</li>
					<li>Register and set up your chosen domain name (subject to availability).</li>
					<li>Provide ongoing support and personalized online tooling for any updates you wish to make to your portfolio.</li>
				</ul>
				<p className="text-gray-600"></p>
			</div>

			<div className="bg-white shadow-md w-full rounded-lg p-8 mt-4">
				<h2 className="text-2xl text-gray-800 mb-4">About Me</h2>
				<div className="space-y-4">
					{entries.map((entry, index) => {
						return (
							<div key={`entry_${index}`} className="border p-4 rounded-lg flex flex-col md:flex-row items-center">
								<div className="mb-4 md:mb-0 md:flex-shrink-0">
									<Avatar src={andy_small} />
								</div>
								<div className="md:ml-4 text-center md:text-left">
									<h3 className="text-lg font-semibold">{entry.name}</h3>
									<p className="text-sm text-gray-500">{entry.date}</p>
									<a href={entry.url} rel="noreferrer" target="_blank" onMouseDown={() => pageVisit(entry.url)} className="text-blue-500">{entry.url}</a>
								</div>
							</div>
						)
					})}
				</div>
			</div>
			<p><a href="/privacy-policy.html" target="_blank" className='text-slate-300'>Privacy Policy</a></p>
		</div >
	)
}

export default App
